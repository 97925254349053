<template>
  <div class="group-filters">
    <div class="search_filter">
      <custom-input
          :delay="800"
          name="staff-passings-sort"
          label="Название"
          :label-width="40"
          :inputWidth="60"
          :placeholder="'Введите имя'"
          cleanable
          v-model="searchParams.nameField"
      />
    </div>
    <div class="count_filter">
      <custom-input
          name="custom-number-filter"
          type="number"
          v-model="searchParams.count"
          label="Количество участников"
          :placeholder="'От'"
          :label-width="60"
          :inputWidth="40"
      />
    </div>
  </div>

  <div v-if="groups">

    <base-table
        v-if="shownData.length"
        :columns="groupFields"
        :rows="shownData"
        :rowSelect="onRowSelected"
        :checkedList="[selectedGroup]"
    />

    <table-pagination
        v-if="shownData.length"
        :key="updatePagination"
        :totalPages="1"
        :countOnPage="countOnPage"
        :total="totalResults ?? rowData.length"
        :count="countOnPage > shownData.length ? shownData.length : countOnPage"
        @changePage="changePage"
    />

    <div v-else class="no_results">
      <p>Нет результатов</p>
    </div>

  </div>
  <div v-else class="table-preloader">
    <preloader/>
  </div>
</template>

<script>
import {reactive, ref} from "@vue/reactivity";
import {useStore} from "vuex";
import {computed, onBeforeMount, watch} from "@vue/runtime-core";
import Preloader from "@/components/Technical/Preloader.vue";
import CustomInput from "@/components/Forms/Fields/CustomInput.vue";
import CustomSelect2 from "@/components/Forms/Fields/CustomSelect2.vue";
import BaseTable from "@/components/Tables/BaseTable.vue";
import TablePagination from "@/components/OtherElements/TablePagination.vue";

export default {
  name: "group-table",
  emits: ["groupSelected"],
  components: {
    TablePagination,
    BaseTable,
    Preloader,
    CustomInput,
    CustomSelect2,
  },
  setup(props, context) {
    const store = useStore();

    const countOnPage = ref(15),
        page = ref(1),
        isPreloader = ref(false),
        searchParams = reactive({
          nameField: "",
          count: null,
        });
    const groups = computed(() => store.state.group.groups),
        rowData = computed(() => {
          return groups.value.map((group) => {
            return {
              id: group.id,
              name: group.name,
              edit: group.id,
              owner: group.owner.name,
              usersCount: group.users_count,
            };
          });
        });

    const selectedGroup = ref(null);

    const onRowSelected = (id) => {

      if (selectedGroup.value === id) {
        selectedGroup.value = null;
      } else {
        selectedGroup.value = id;
      }

      context.emit(
          "groupSelected",
          selectedGroup.value
      );
    };

    onBeforeMount(() => {
      store.dispatch("group/getGroups");
    });

    const groupFields = [
      {
        field: "check",
        headerName: "",
        checkAll: false,
      },
      {
        field: "name",
        headerName: "Название группы",
        link: true,
        source: "group",
      },
      {
        field: "usersCount",
        headerName: "Количество участников",
      },
      {
        field: "edit",
        headerName: "",
        dest: "group",
        group: true,
      },
    ];

    const pageDataStart = ref(0);
    const updatePagination = ref(false);
    const totalResults = ref(0);

    const shownData = computed(() => {

      let data = rowData.value ?? [];

      if (data.length) {

        if (searchParams.nameField) {
          data = data.filter((el) =>
              el.name.toLowerCase().includes(searchParams.nameField.toLowerCase())
          );
        }

        if (searchParams.count) {
          data = data.filter((el) => el.usersCount >= searchParams.count);
        }

        totalResults.value = data.length;
        data = data.slice(pageDataStart.value, pageDataStart.value + countOnPage.value);
      }

      return data;
    });

    const changePage = (page) => {
      pageDataStart.value = (page - 1) * countOnPage.value;
    };

    watch(
        () => [searchParams.nameField, searchParams.count],
        () => {
          pageDataStart.value = 0;
          updatePagination.value = !updatePagination.value;
        },
    )

    return {
      countOnPage,
      isPreloader,
      page,
      changePage,
      groups,
      searchParams,

      rowData,
      onRowSelected,

      shownData,
      groupFields,
      updatePagination,
      totalResults,
      selectedGroup
    };
  },
};
</script>

<style lang="scss" scoped>

.no_results {
  padding: 40px 10px;
}

.group-filters {
  width: 700px;
  display: flex;
}

.search_filter {
  width: 400px;
  flex-grow: 1;
}

@media (max-width: 1251px) {
  .no_results {
    padding: 40px 10px;
  }
}

@media (max-width: 768px) {
  .search_filter {
    width: 100%;
  }

  .group-filters {
    width: 100%;
    flex-direction: column;
  }
}
</style>